import React from 'react'
import ReCAPTCHA from "react-google-recaptcha";

const recaptchaRef = React.createRef();

const handleSubmit = () => {
  // Trigger event code here
  window.dataLayer.push({
    event: 'formSubmission',
    formType: 'contactForm',
    formName: 'Schedule Your Open House',
  });
}

const OpenHousesContactSectionNew = () => {
  const [buttonDisabled, setButtonDisabled] = React.useState(true)

  return (
    <div id="schedule" className='bg-site-indigo-100 py-16 px-0 md:px-6'>
      <div className='w-full lg:w-1/2 mx-auto'>
        <h2 className='text-4xl md:text-5xl text-site-lightblue text-center font-bold mb-12'>Schedule Your Open House</h2>
        <p className='font-body w-full text-xl text-center text-gray-800 mb-16'>Contact us to schedule your <strong>Open House</strong> and learn more on how HireLive can help you see qualified candidates face to face on the day, time and location you prefer.</p>
      </div>

      <div className='w-11/12 bg-white max-w-screen-sm mx-auto mb-6 px-8 py-16'>
        <div className='w-full mx-auto'>
          <form
            action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
            method="POST"
            onSubmit={handleSubmit}
          >
            <input type="hidden" name="oid" value="00DU0000000KsOL" />
            <input type="hidden" name="retURL" value="https://www.hirelive.com/thank-you-open-house" />

            <label className="font-body block tracking-wide text-gray-900 font-medium mb-2" htmlFor="first_name">First Name <span className='text-red-500'>*</span></label><input className="appearance-none block w-full bg-white text-gray-700 border-2 py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white rounded" id="first_name" maxLength="40" name="first_name" size="20" type="text" required /><br/>

            <label className="font-body block tracking-wide text-gray-900 font-medium mb-2" htmlFor="last_name">Last Name <span className='text-red-500'>*</span></label><input className="appearance-none block w-full bg-white text-gray-700 border-2 py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white rounded" id="last_name" maxLength="80" name="last_name" size="20" type="text" required /><br/>

            <label className="font-body block tracking-wide text-gray-900 font-medium mb-2" htmlFor="company">Company <span className='text-red-500'>*</span></label><input className="appearance-none block w-full bg-white text-gray-700 border-2 py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white rounded" id="company" maxLength="40" name="company" size="20" type="text" required /><br/>

            <label className="font-body block tracking-wide text-gray-900 font-medium mb-2" htmlFor="email">Email <span className='text-red-500'>*</span></label><input className="appearance-none block w-full bg-white text-gray-700 border-2 py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white rounded" id="email" maxLength="80" name="email" size="20" type="text" required /><br/>

            <label className="font-body block tracking-wide text-gray-900 font-medium mb-2" htmlFor="phone">Phone <span className='text-red-500'>*</span></label><input className="appearance-none block w-full bg-white text-gray-700 border-2 py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white rounded" id="phone" maxLength="40" name="phone" size="20" type="text" required /><br/>

            <label className="font-body block tracking-wide text-gray-900 font-medium mb-2" htmlFor="phone">Message <span className='text-red-500'>*</span></label><textarea className="appearance-none block w-full bg-white text-gray-700 border-2 py-3 px-4 leading-tight focus:outline-none focus:bg-white rounded" id="00N6Q000003zBgl" name="00N6Q000003zBgl" rows="3" type="text" wrap="soft"></textarea><br></br>

            <select id="lead_source" name="lead_source" className='hidden'>
              <option defaultValue="Website">Website</option>
            </select>

            <select id="00N6Q000003zCfA" name="00N6Q000003zCfA" className='hidden'>
              <option defaultValu="Open House Service">Open House Service</option>
            </select><br></br>

            <div className='g-recaptcha flex justify-center mb-6'>
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey="6LfiKfoeAAAAAOCubM9CnyP42_1AxD0X7zzcbMTX"
                onChange={() => setButtonDisabled(false)}
              />
            </div>

            <div className="flex justify-center">
              <input
                id="contact_form_submit"
                className={`${buttonDisabled ? "disabled:bg-gray-300" : ""} w-1/2 bg-site-green-900 cursor-pointer hover:bg-ff-blue-9 text-white text-xl rounded py-4 px-12 md:px-8 mx-auto`}
                type="submit"
                name="submit"
                disabled={buttonDisabled}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default OpenHousesContactSectionNew