import * as React from "react"

import LayoutOpenHouses from "../components/layout/landing/layout-open-houses"
import Seo from "../components/seo"
import OpenHousesContactSectionNew from "../components/sections/open-houses-contact-section-new"

const ScheduleOpenHousePage = () => (
  <LayoutOpenHouses>
    <Seo
      title="Schedule Your Open House"
      description="Contact us to schedule your open houe and learn more on how HireLive can help you see qualified candidates face to face on the day, time and location you prefer."
    />
    <OpenHousesContactSectionNew />
  </LayoutOpenHouses>
)

export default ScheduleOpenHousePage
