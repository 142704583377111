import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import MenuDropdown from "../menu-dropdown"
import MobileDrawer from "./mobile-drawer-open-houses"

const HeaderOpenHouses = () => {
  return (
    <div className="bg-white w-full p-4 z-50">

      <header className="w-full lg:w-11/12 mx-auto">
        <nav className="flex items-center justify-between mx-auto">
          <Link to="/">
            <div className="md:w-56 flex items-center">
              <div>
                <StaticImage
                  src="../../../images/logos/hirelive-logo.png"
                  alt="HireLive"
                />
              </div>
            </div>
          </Link>

          <div className="flex items-center justify-center">
            <div className="hidden xl:block mr-12 2xl:mr-0">
              <Link to="/">
                <button
                  className="text-base text-gray-900 font-display font-semibold uppercase tracking-wider"
                >
                  Home
                </button>
              </Link>
            </div>

            <MenuDropdown />

            <ul className="hidden 2xl:flex flex-grow items-center justify-center flex-wrap space-x-12 mb-0">
              <li>
                <Link to="/open-houses">
                  <button
                    className="text-base text-gray-900 font-display font-semibold uppercase tracking-wider"
                  >
                    Open Houses
                  </button>
                </Link>
              </li>
              <li>
                <Link to="/marketing-campaign">
                  <button
                    className="text-base text-gray-900 font-display font-semibold uppercase tracking-wider"
                  >
                    Marketing Campaign
                  </button>
                </Link>
              </li>
              <li>
                <Link to="/resume-service">
                  <button
                    className="text-base text-gray-900 font-display font-semibold uppercase tracking-wider"
                  >
                    Resume Service
                  </button>
                </Link>
              </li>
              <li>
                <Link to="/contingency-search">
                  <button
                    className="text-base text-gray-900 font-display font-semibold uppercase tracking-wider"
                  >
                    Contingency Search
                  </button>
                </Link>
              </li>
            </ul>

            <ul className="hidden xl:flex flex-grow items-center justify-center flex-wrap space-x-12 2xl:ml-12 mb-0">
              <li>
                <Link to="/testimonials">
                  <button
                    className="text-base text-gray-900 font-display font-semibold uppercase tracking-wider"
                  >
                    Testimonials
                  </button>
                </Link>
              </li>
              <li>
                <Link to="/contact">
                  <button
                    className="text-base text-gray-900 font-display font-semibold uppercase tracking-wider"
                  >
                    Contact Us
                  </button>
                </Link>
              </li>
            </ul>
          </div>

          <div className="hidden xl:block flex-shrink-0 md:w-64">
            <Link to="/schedule-open-house">
              <button
                className="bg-site-green-900 hover:bg-site-green-800 text-white uppercase font-display font-bold py-3 px-6"
              >
                Schedule Your Open House
              </button>
            </Link>
          </div>

          <MobileDrawer />
        </nav>
      </header>
    </div>
  )
}

export default HeaderOpenHouses