import * as React from "react"
import PropTypes from "prop-types"

import HeaderOpenHouses from "./header-open-houses"
import Footer from "../footer"

const LayoutOpenHouses = ({ children }) => (
  <>
    <HeaderOpenHouses />
    <div>
      <main>
        {children}
      </main>
    </div>
    <Footer />
  </>
)

LayoutOpenHouses.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutOpenHouses